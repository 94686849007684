//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import subscribeToNewsletter from '~/queries/mutations/newsletter.graphql';

export default {
    props: ['widget'],
    data() {
        return {
            newsletterEmail: '',
        };
    },
    methods: {
        async newsLetterSubmit() {
            try {
                const data = await this.$apollo.mutate({
                    mutation: subscribeToNewsletter,
                    variables: {
                        email: this.newsletterEmail,
                    },
                });
                this.$notify.success('Uutiskirjeen tilaaminen onnistui!');
            } catch (e) {
                this.$notify.error(e.message);
            }
        },
    },
};
