import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=34df1e64&"
import script from "./Contact.vue?vue&type=script&lang=js&"
export * from "./Contact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusRenderInput: require('/~/src/modules/opus/components/Form/RenderInput.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default})
