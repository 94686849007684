import { render, staticRenderFns } from "./Form.vue?vue&type=template&id=4556a8be&"
import script from "./Form.vue?vue&type=script&lang=js&"
export * from "./Form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,ContentfulContact: require('/~/src/modules/contentful/components/Widget/Form/Contact.vue').default,OpusRenderInput: require('/~/src/modules/opus/components/Form/RenderInput.vue').default,OpusButton: require('/~/src/modules/opus/components/Form/Button.vue').default})
