//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mutation from '~/queries/mutations/sendContactForm.graphql';

export default {
    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                email: '',
                message: '',
                telephone: '',
            },
        };
    },
    methods: {
        async onSubmit() {
            try {
                const token = await this.$recaptcha.execute('login');

                const contactFormData = {
                    name: `${this.form.firstname} ${this.form.lastname}`,
                    email: this.form.email,
                    comment: this.form.message,
                    telephone: this.form.telephone,
                };

                const data = await this.$apollo.mutate({
                    mutation,
                    variables: {
                        input: contactFormData,
                    },
                    context: {
                        headers: {
                            'X-ReCaptcha': token,
                        },
                    },
                });

                this.$refs.contactForm.reset();

                this.$notify.success(this.$t('contactus.successfull'));
            } catch (e) {
                this.$notify.error(this.$t('contactus.unsuccessfull'));
            }
        },
    },
    async mounted() {
        try {
            await this.$recaptcha.init();
        } catch (e) {
            console.error(e);
        }
    },
    beforeDestroy() {
        this.$recaptcha.destroy();
    },
};
